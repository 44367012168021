(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/game-type-tag/assets/javascripts/game-type-tag.js') >= 0) return;  svs.modules.push('/components/marketplace/game-type-tag/assets/javascripts/game-type-tag.js');
"use strict";


const {
  games: gameHelpers
} = svs.components.marketplace.helpers;
const GameTypeTag = _ref => {
  let {
    productId,
    subProductId,
    className
  } = _ref;
  const gameTag = gameHelpers.getSubProductTag(productId, subProductId);
  if (!gameTag) {
    return null;
  }
  const classNames = [className, 'game-type-tag', 'f-medium', "bg-".concat(gameTag.color)].filter(Boolean).join(' ');
  return React.createElement("div", {
    style: {
      width: '65px'
    }
  }, React.createElement("div", {
    className: classNames
  }, gameTag.tag));
};
setGlobal('svs.components.marketplace.gameTypeTag.GameTypeTag', GameTypeTag);

 })(window);